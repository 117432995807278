.box.report{
    .table{
        font-size: .8125rem;
        thead.table-heading{
            th{
                background-color: #f1f1f1;
            }
        }
    }
    header{
        background-color: var(--bs-primary);
        padding: .75rem 1.5rem;
    }
    .report-heading{
        font-size: 1rem;
        font-weight: 700;
        line-height: 1;
        color: #fff;
        margin-bottom: 0;
    }
}
@media print{
    html,body{
        font-size: 9pt;
        -webkit-print-color-adjust: exact !important;
    }
    header.app-header,
    .wizard-heading,
    footer.app-footer,
    .print-cta{
        display: none !important;
    }
    .full-container{
        max-width: none !important;
    }
    .boxed{
        box-shadow: none !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}
.table-report{
    td.warning{
            color: #664d03;
            background-color: #fff3cd;
            border-color: #ffecb5;
    }
    td.success{
            color: #0f5132;
            background-color: #d1e7dd;
            border-color: #badbcc;
    }
}