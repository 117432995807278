header.app-header{
    border-bottom: solid 10px var(--bs-primary);
    position: relative;
    background-image: url('../../images/img-cover.jpeg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @include media-breakpoint-up(md)
    {
        height: 125px;
    }
    &:before{
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background: rgba(12, 12, 12, 0.5);
    }
    .logo{
        position: relative;
        z-index: 2;
        width: #{'min(90%, 350px)'};
    }
}
.user-badge{
    border-radius: 5px;
}