.wizard-heading{
    .wizard-icon{
        font-size: 2.75rem;
    }
}
.wizard-status{
    span{
        display: flex;
        line-height: 1;
        width: 2rem;
        height: 2rem;
        align-items: center;
        justify-content: center;
        background-color: var(--bs-mid);
        color: var(--bs-white);
        border-radius: 50%;
        font-weight: 600;
        position: relative;
        &.current{
            background-color: var(--bs-dark);
        }
        & + span{
            margin-left: 1rem;
            &:before{
                content: '';
                display: block;
                width: 1rem;
                height: 1px;
                background-color: var(--bs-mid);
                position: absolute;
                top: 50%;
                right: 100%;
            }
        }
    }
}
#map-container{
    height: 100vw;
    @include media-breakpoint-up(lg){
        height: 100%;
    }
    .leaflet-container{
      height: 100%;
    }
}
#map{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
}
.app-main{
    
}
.step{
    .info-block{
        .info-icon {
            margin: 0px auto 2rem auto;
            background: #F8F8F8;
            border-radius: 50%;
            width: 8rem;
            height: 8rem;
            font-size: 3rem;
            @include media-breakpoint-up(md)
            {
                width: 16rem;
                height: 16rem;
                font-size: 7rem;
            }
        }
    }
}
.map-input{
    position: relative;
    z-index: 3;

}
.temperature-calculator{
    input[type="text"]{
        border: none;
        background-color: rgba(255,255,255,.9);
        font-weight: 500;
        padding-left: 1rem;
        padding-right: 1rem;
        &::placeholder{
            color: #999;
            opacity: 1;
        }
    }
}
.deg-suffix{
    position: relative;
    &:after{
        content: '°C';
        position: absolute;
        line-height: 1;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        top: 0;
        right: 0;
        padding-right: 1rem;
    }
    input[type=text]{
        padding-right: 2.75rem;
    }
}
.arrow-separator{
    font-size: 2rem;
}

.autocomplete-res{
   background-color: #fff;
   font-size: .875rem;
   list-style: none;
   margin: 0;
   padding: 0;
   li{
       border-bottom: solid 1px #eaeaea;
   }
}
.autocomplete-field{
   background: #fff;
   &.loading{
       background: #fff url('https://cdnjs.cloudflare.com/ajax/libs/galleriffic/2.0.1/css/loader.gif') no-repeat right 10px center / 18px;
   }
}