@mixin reset-list() {
    list-style: none;
    padding: 0;
}

@mixin reset-button() {
    margin: 0;
    padding: 0;
    line-height: 1;
    appearance: none;
    background-color: transparent;
    border: none;
}

@mixin fluid-type($min-vw, $max-vw, $min-value, $max-value, $property: "font-size") {
    $factor: 1 / ($max-vw - $min-vw) * ($max-value - $min-value);
    $calc-value: unquote("calc(#{ $min-value - ($min-vw * $factor) } + #{ 100vw * $factor })");
    min-height: 0vw; //per supporto Safari
    #{$property}: clamp(
        #{if($min-value > $max-value, $max-value, $min-value)},
        #{$calc-value},
        #{if($min-value > $max-value, $min-value, $max-value)}
    );
}

@mixin aspect-ratio($width, $height) {
    aspect-ratio: $width / $height;
    // Fallback (current, using padding hack)
    &::before {
        float: left;
        padding-top: calc( 100% / #{$width / $height} );
        content: "";
    }

    &::after {
        display: block;
        content: "";
        clear: both;
    }
}
