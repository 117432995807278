@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";
$theme-colors:map-merge($theme-colors, (
    "primary": #DC332C,
    "secondary": #CC851B,
    "tertiary": #fcb988,
    "quaternary": #cfdfb6,
    "dark": #0C0C0C,
    "mid": #999999,
    "white": #ffffff,
    "grey": #cccccc,
    "light-grey": #f1f1f1,
    "lighest": #f8f8f8,
));

$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
$utilities-text-colors: map-loop($utilities-colors, rgba-css-var, "$key", "text");
$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, "$key", "bg");

$spacer: 1rem !default;

$grid-gutter-width: 2rem !default;
$border-radius: 0;
$input-border-radius: 0;
$input-border-radius-sm: 0;
$input-border-radius-lg: 0;
$enable-negative-margins: true;
