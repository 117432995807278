
.configuration-result{
    .result-form{
        display: block;
        & + form.result-form{
            margin-top: 2rem;
        }
    }
    h4{
        text-transform: uppercase;
        font-size: 1.25rem;
        text-align: center;
        letter-spacing: .1rem;
        color: var(--bs-primary);
        font-weight: 500;
    }
    aside{
        writing-mode: vertical-lr;
        text-orientation: sideways;
        transform: rotate(180deg);
        text-transform: uppercase;
        font-size: 1.25rem;
        text-align: center;
        letter-spacing: .1rem;
        color: var(--bs-grey);
        padding-left: 2.5rem;
    }
    .configuration-result-table{
        table-layout: fixed ;
        thead{
            th{
                text-align: center;
            }
        }
        tbody{
            td{
                font-size: .875rem;
                height: 110px;
                @include media-breakpoint-up(lg)
                {
                    font-size: 1.25rem;
                }
                span{
                    line-height: 1;
                    display: inline-flex;
                    background-color: #f1f1f1;
                    border-radius: 5px;
                    padding: .25rem .5rem;
                }
                input[type="radio"]:checked + label{
                    p.radio{
                        i.fa-circle-dot{
                            display: inline;
                        }
                        i.fa-circle{
                            display: none;
                        }
                    }
                }
                label{
                    line-height: 1;
                    p.radio{
                        position: absolute;
                        left: 0;
                        top: 100%;
                        width: 100%;
                        text-align: center;
                        padding-top: .25rem;
                        i.fa-circle-dot{
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
.d-grid{
    &.grid-columns-2{
        grid-template-columns: 1fr 1fr;
    }
}

.list-group{
    margin: 2rem 0px;
    text-align: left;
    .list-group-item {
        position: relative;
        display: block;
        padding: 0.75rem 1.25rem;
        margin-bottom: -1px;
        background-color: #fff;
        border: 1px solid rgba(0,0,0,.125);
        border-color: #EBEBEB;
        border-left: 0px;
        border-right: 0px;
        border-radius: 0px;
        label{
            i{
                color: var(--bs-primary);
                font-size: 2rem;
                display: inline-flex;
                width: 40px;
                justify-content: center;
                margin-right: .5rem;
            }
        }
        .data {
            font-size: 1.3em;
            color: var(--bs-primary);
        }
    }
}
.results-table{
    font-size: .8125rem;
    td{
        &[data-valid]{
            min-width: 80px;
        }
        &[data-valid="1"]{
            cursor: pointer;
        }
        span{
            display: block;
            border-radius: 3px;
        }
    }
    .danger{
        color: #842029;
        background-color: #f8d7da;
        border-color: #f5c2c7;
    }
    .warning{
        color: #664d03;
        background-color: #fff3cd;
        border-color: #ffecb5;
    }
    .success{
        color: #0f5132;
        background-color: #d1e7dd;
        border-color: #badbcc;
    }
}
.table{
    margin-bottom: 0;
    td,th{
        padding: .5rem;
        line-height: 1;
    }
    .r1-c1{
        font-weight: 700;
        min-width: 30px;
        span{
            transform: rotate(180deg);
            writing-mode: vertical-rl;
            white-space: nowrap
        }
    }
    .r1-c3{
        font-size: .75rem;
    }
    .r3-c1{
        font-size: .6875rem;
        min-width: 28px;
        span{
            transform: rotate(180deg);
            writing-mode: vertical-rl;
            white-space: nowrap
        }
    }
}
.inverter-table-output{
    padding: .5rem;
    border: solid 1px #333;
    .table + .table{
        margin-top: 5px;
    }
}
.table-output-block-cta{
    border: solid 1px #333;
    margin-top: -1px;
}
.table-output-block-cta-btn{
    background-color: var(--bs-primary);
    color: #fff;
    &:disabled{
        background-color: #ccc;
        color: #999;
    }
}
input[name*="selection"] + label{
    cursor: pointer;
    .fa-circle-dot{
        display: none;
    }
}
input[name*="selection"]:checked + label{
    .fa-circle-dot{
        display: inline-block;
    }
    .fa-circle{
        display: none;
    }
}
.table-output-block + .table-output-block.no-results{
    margin-top: 2rem;
}
.table-output-block-header{
    padding: .5rem .75rem;
    background-color: var(--bs-primary);
    color: #fff;
    border-left: solid 1px #000;
    border-top: solid 1px #000;
    border-right: solid 1px #000;
    line-height: 1;
}
.table-output-block-heading{
    margin-bottom: 0;
    font-size: 1.125rem;
}
.table-output-block-total-modules{
    font-size: .875rem;
    font-weight: 700;
}