.inverter-type-selection{
    border-radius: 100px;
    input[type="radio"]:checked + label{
        background-color: var(--bs-primary);
        color: #fff;
    }
    input[type="radio"] + label{
        background-color: #eaeaea;
        color: #999;
        line-height: 1;
        display: flex;
        width: calc(100% / 3);
        flex: 0 0 calc(100% / 3);
        padding: 1rem 1.5rem;
        align-items: center;
        justify-content: center;
        text-align: center;
        position: relative;
        @include media-breakpoint-up(lg)
        {
            padding: 1rem 2rem;
        }
        .badge{
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 1;
            font-size: .875rem;
            width: 24px;
            height: 24px;
            background-color: #444;
            border-radius: 50%;
            top: -12px;
            color: #fff;
        }
        &:first-of-type{
            border-radius: 100px 0 0 100px;
        }
        &:last-of-type{
            border-radius: 0 100px 100px 0;
        }
    }
}
.box-card{
    border: solid 3px #fff;
    box-shadow: 0px 0px 10px rgba(12,12,12,.1);
    border-radius: 5px;
    @include media-breakpoint-up(md)
    {
        width: 210px;
    }
}
.inverters-grid{
    display: inline-grid;
    grid-template-columns: 1fr;
    grid-gap: 1.15rem;
    @include media-breakpoint-between(md,xxl){
        grid-template-columns: 1fr 1fr 1fr;
    }
    @include media-breakpoint-up(xxl){
        grid-gap: 1.5rem;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    input[type="checkbox"]:checked + label{
        
        i.fa-square-check{
            display: inline;
        }
        i.fa-square{
            display: none;
        }
    }
    .box-card{
        &.selected{
            border-color: var(--bs-primary);
        }
        figure{
            font-size: 4.5rem;
            position: relative;
            color: #999;
            cursor: pointer;
            .figure-ui-wrapper{
                width: 142px;
                height: 142px;
                margin: 0px auto;
                padding: 1rem;
            }
        }
        .card-body{
            line-height: 1.3;
            small{
                display: block;
            }
            i.fa-square-check{
                display: none;
            }
        }
    }
}
.inverter-detail{
    .inverter-detail-content{
        ul{
            list-style: none;
            padding: 0;
            margin: 0;
            li{
                position: relative;
                padding-left: 16px;
                &:before{
                    content: '•';
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                }
            }
        }
    }
}
#inverterDetailModal{
    .table{
        font-size: .875rem;
        th{
            padding-left: .75rem;
        }
    }
}

.inverters-mppt-group{
    .group-heading{
        font-size: 1.125rem;
        line-height: 1;
        border-bottom: solid 1px #ccc;
        padding-bottom: .5rem;
    }
}