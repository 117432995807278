footer.app-footer{
    border-top: 10px solid var(--bs-primary);
    color: var(--bs-white);
    background: var(--bs-dark);
    padding: 4rem 2rem;
    text-align: center;
    font-size: .875rem;
    font-weight: 500;
    line-height: 2;
    .logo{
        margin: 0px auto;
        margin-bottom: 2rem;
        max-width: 250px;
    }
    a{
        color: #fff;
        text-decoration: none;
        &:hover{
            text-decoration: underline;
        }
    }
}
.language-switcher{
    button{
        appearance: none;
        margin: 0;
        padding: 0;
        border: none;
        background: none;
        line-height: 1;
        & + button{
            margin-left: 1rem;
        }
    }
}