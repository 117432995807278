.modules-table{
    tbody{
        td{
            &.checkbox-cell{
                width: 1rem;
            }
            @include media-breakpoint-up(md)
            {
                padding: 1rem .75rem;
            }
            a{
                &:before{
                    content: attr(data-label);
                    @include media-breakpoint-up(md)
                    {
                        content: unset;
                    }
                }
            }
        }
        input[type="checkbox"] + label{
            font-size: 1.25rem;
            color: var(--bs-grey);
            i.fa-square-check{
                display: none;
            }
        }
        input[type="checkbox"]:checked + label{
            color: var(--bs-primary);
            i.fa-square-check{
                display: inline;
            }
            i.fa-square{
                display: none;
            }
        }
    }
}

.model-brand-selector{
    padding: .75rem 2.25rem .75rem 1.25rem;
}
#custom-module-form{
    width: #{'min(90%, 700px)'};
}
.form-select{
    box-shadow: 0px 2px 5px rgba(0,0,0,.1);
}
.product-info{
    .table{
        font-size: .8125rem;
    }
}