table.requests-archive{
    td{
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    tr{
        transition: opacity .6s ease;
        &.deleting{
            opacity: 0;
        }
    }
}