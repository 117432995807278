@import "~@fancyapps/ui/src/Fancybox/Fancybox.scss";
:root{
    --section-spacer: 12vw;
    @include media-breakpoint-up(md)
    {
        --section-spacer: 6vw;
    }
}
html, body{
    overflow-x: hidden;
    font-size: 100%;
    @include media-breakpoint-up(lg)
    {
        overflow-x: unset;
    }
}
html.no-js{
    [data-src]{
        display: none;
    }
}
body{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 1.75;
    -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
    background-color: var(--bs-light-grey);
}
.btn-round{
    border-radius: 100px;
}
h1,h2,h3,h4{
    font-weight: 400;
}
.text-small{
    font-size: .8175rem;
}
.text-xsmall{
    font-size: .75rem;
}
.reset-list{
    @include reset-list();
}
.reset-button{
    @include reset-button();
}
.data-box{
    width: #{'min(100%, 390px)'};
}
.step{
    .layout-column{
        @include media-breakpoint-up(lg){
            min-height: 100vh;
        }
    }
}
.checkbox-radio{
    .icon{
        display: block;
        float: left;
        line-height: 1;
        .fa-square-check{
            display: none;
        }
    }
    span.disclaimer{
        display: block;
        padding-left: 1.5rem;
        line-height: 1.3;
    }
}
input[type="checkbox"],
input[type="radio"]{
    &:checked + label.checkbox-radio{
        .icon{
            .fa-square-check{
                display: inline;
            }
            .fa-square{
                display: none;
            }
        }
    }
}
.full-container{
    @include media-breakpoint-up(lg)
    {
        max-width: 90%;
        margin: 0px auto;
    }
}
.boxed{
    margin: 0px auto;
    border: solid 3px #fff;
    box-shadow: 0px 0px 10px rgba(12,12,12,.1);
    border-radius: 5px;
}
dialog::backdrop {
    background-color: rgba(0,0,0,.4);
    backdrop-filter: blur(3px);
}
.dialog-alert{
    width: 100%;
    max-width: 500px;
    padding: 2.25rem;
    border: solid 1px #ccc;
    position: fixed;
    z-index: 900;
    left: 50%;
    top: 2rem;
    margin: 0px;
    transform: translateX(-50%);
    border-radius: 5px;
}
.close-dialog{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    &:focus{
        outline-style: none;
        box-shadow: none;
    }
}
.small-spinner{
    width: 16px;
    height: 16px;
    border: solid 3px #999;
    border-left-color: transparent;
    border-radius: 50%;
    animation: spinner .4s infinite linear;
}

.validation-error{
    font-size: .75rem;
    line-height: 1;
    white-space: nowrap;
    position: absolute;
    left: 0;
    top: 100%;
    margin-top: 5px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--bs-primary);
}

@keyframes spinner{
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}
.alert{
    padding-right: 3rem;
    button.btn-close{
        position: absolute;
        right: 1rem;
    }
}
@for $i from 1 through 10 {
    .z-index-#{$i}{
        z-index: #{$i};
    }
}
.round{
    border-radius: 200px;
}
.w-0{
    width: 0;
    max-width: 100%;
}
.table-layout-fixed{
    table-layout: fixed;
}
.form-select, .btn{
   font-size: .9rem;
}