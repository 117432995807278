.dashboard{
    min-height: 100vh;
}
.dashboard-sidebar{
    @include media-breakpoint-up(lg)
    {
        height: 100vh;
        position: sticky;
        top: 0;
    }
    .logo{
        max-width: 240px;
        @include media-breakpoint-up(lg)
        {
            max-width: 210px;
        }
    }
    @include media-breakpoint-up(lg)
    {
        max-width: 300px;
    }
    .pic{
        width: 80px;
        height: 80px;
        flex: 0 0 80px;
        border-radius: 50%;
        border: solid 6px rgba(255,255,255,.2);
        font-size: 1.5rem;
        background-color: rgba(0,0,0,.1);
    }
    .aside-body{
        margin-top: 5vw;
        nav{
            li{
                & + li{
                    margin-top: 1rem;
                }
            }
            .btn{
                color: #fff;
                text-decoration: none;
                display: flex;
                line-height: 1;
                align-items: center;
                font-size: .875rem;
                &:not(.active):hover{
                    background-color: rgba(0,0,0,.1);
                }
                &.active{
                    background-color: #fff;
                    color: #000;
                    span.label{
                        font-weight: 700;
                    }
                }
                span.icon{
                    font-size: 1.125rem;
                    display: block;
                    width: 36px;
                }
            }
        }
    }
}
.dashboard-body{
    border-radius: .25rem 0 0;
    box-shadow: -2px -2px 3px rgba(0,0,0,.05);
}
.mobile-nav-push{
    height: 80px;
}
.mobile-nav{
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;

    ul{
        height: 80px;
        padding: 10px;
    }
    li{
        a{
            line-height: 1;
            border-radius: 5px;
            text-decoration: none;
            &.active{
                background-color: rgba(0,0,0,.1);
            }
            span{
                display: block;
                &.icon{
                    font-size: 1.5rem;
                }
                &.label{
                    font-size: .75rem;
                }
            }
        }
    }
}

.detail-table{
    .detail-row{
        label,
        div.value{
            padding: .75rem 0;
            vertical-align: baseline;
            border-bottom: solid 1px #ccc;
        }
        label{
            i{
                font-size: 1.75rem;
                text-align: center;
                padding: 0;;
                width: 40px;
                margin-right: 1rem;
            }
        }
    }
}